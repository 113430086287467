<template>
  <v-sheet class="pa-4" :class="!darkmode && 'grey lighten-5'">
    <v-row no-gutters>
      <v-btn
        class="font-weight-bold mb-3"
        depressed
        @click="
          $router.push(
            `/academic/e-learning/${param.year}/${param.grade}/${param.class}/${param.id}`
          )
        "
      >
        <v-icon class="mr-2">
          mdi-arrow-left
        </v-icon>
        E-Learning
      </v-btn>
    </v-row>

    <v-row no-gutters>
      <v-card
        class="rounded-lg pa-4"
        :class="darkmode ? 'black' : 'white'"
        flat
        width="100%"
      >
        <v-row no-gutters align="start">
          <v-col cols="6" sm="4" class="mt-2">
            <div class="font-weight-light">{{ $t("subjects.subjects") }}</div>
            <div class="font-weight-bold">
              {{ elearning.name }}
              <span v-if="elearning.sub">{{ elearning.sub }}</span>
            </div>
          </v-col>
          <v-col cols="6" sm="4" class="pl-2 mt-2">
            <div class="font-weight-light">{{ $t("app.title") }}</div>
            <div class="font-weight-bold">{{ elearning.title }}</div>
          </v-col>
          <v-col cols="6" sm="4" class="pl-2 mt-2">
            <div class="font-weight-light">{{ $t("app.date") }}</div>
            <div class="font-weight-bold">
              {{ elearning.date | dateFormat }}
            </div>
          </v-col>
          <v-col cols="12" class="mt-2">
            <div class="font-weight-light">
              {{ $t("basic_competencies.basic_competencies") }}
            </div>
            <div
              class="font-weight-bold"
              v-for="bc in elearning.kd"
              :key="bc.kd_title"
            >
              {{ bc.kd_chapter }}
            </div>
          </v-col>
          <v-col
            v-if="checkPrivilages('ELR05')"
            cols="12"
            :class="$vuetify.breakpoint.lgAndDown && 'mt-4'"
          >
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
            >
              <div
                v-if="selectedElearning.length > 0"
                :class="{ 'pb-3': $vuetify.breakpoint.xsOnly }"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'publish')"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.publish") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'unpublish')"
                    >
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.unpublish") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="primary"
                      class="mr-3"
                      small
                      @click="setAction(null, 'delete')"
                    >
                      <v-icon>mdi-delete-forever</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("app.delete") }}
                </v-tooltip>
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isTeacherCreator()"
                color="primary"
                depressed
                small
                class="mr-1"
                @click="modalAddStatus = true"
              >
                {{ $t("elearning.content") }}
                <v-icon small class="pl-1">mdi-plus-circle-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="isTeacherCreator()"
                color="primary"
                depressed
                small
                @click="modalCopyStatus = true"
              >
                {{ $t("app.copy") }} {{ $t("app.from") }}
                <v-icon small class="pl-1">mdi-content-copy</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <AddContent
        :dialog="modalAddStatus"
        :tempEdit="tempEdit"
        :closeModal="
          param => {
            if (param == 'save') this.getData();
            modalAddStatus = false;
            tempEdit = {};
          }
        "
        :lessonId="lessonId"
      />

      <CopyContent
        :dialog="modalCopyStatus"
        :closeModal="() => (modalCopyStatus = false)"
        :lessonId="lessonId"
        :lesson="elearning.name"
        :getData="getData"
        :getUrl="getUrl"
      />

      <v-card
        :class="darkmode ? 'black' : 'white'"
        class="rounded-lg pa-4 mt-4"
        flat
        width="100%"
      >
        <v-data-table
          v-model="selectedElearning"
          :headers="
            $store.getters.g_role_type === 'STUDENT'
              ? tableHeadersStudent
              : tableHeaders
          "
          :items="dataTable"
          :page.sync="queryData.page"
          :items-per-page="queryData.limit"
          :loading="loading"
          :show-select="
            $store.getters.g_role_type != 'STUDENT' && isTeacherCreator()
          "
          hide-default-footer
          class="elevation-0"
        >
          <template #item.description="{item}">
            <span v-html="truncateHtml(item.description)" />
          </template>
          <template #item.status="{item}">
            <span>{{
              item.status == "UNPUBLISHED"
                ? $t("elearning.table.unpublish")
                : $t("elearning.table.published")
            }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu
              v-if="checkPrivilages('ELR06') && isTeacherCreator()"
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="selectedElearning.length == 0 ? 'primary' : ''"
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list v-if="selectedElearning.length == 0">
                <v-list-item @click="setAction(item, 'edit')">
                  <v-icon color="primary" class="mr-2"
                    >mdi-pencil-box-outline</v-icon
                  >
                  {{ $t("app.edit") }}
                </v-list-item>
                <v-list-item
                  v-if="item.status !== 'PUBLISHED'"
                  @click="setAction(item, 'publish')"
                >
                  <v-icon color="primary" class="mr-2"
                    >mdi-check-circle-outline</v-icon
                  >
                  {{ $t("app.publish") }}
                </v-list-item>
                <v-list-item
                  v-if="item.status !== 'UNPUBLISHED'"
                  @click="setAction(item, 'unpublish')"
                >
                  <v-icon color="primary" class="mr-2"
                    >mdi-close-circle-outline</v-icon
                  >
                  {{ $t("elearning.cancel_publish") }}
                </v-list-item>
                <v-list-item @click="setAction(item, 'delete')">
                  <v-icon color="primary" class="mr-2"
                    >mdi-delete-forever</v-icon
                  >
                  {{ $t("app.delete") }}
                </v-list-item>
                <v-list-item @click="setAction(item, 'viewstudent')">
                  <v-icon color="primary" class="mr-2">mdi-eye</v-icon>
                  {{ $t("app.view") }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-else
              icon
              small
              dark
              class="primary"
              :disabled="loading"
              @click="setAction(item, 'viewstudent')"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />
      </v-card>
    </v-row>

    <DetailContent
      :dialog="detailStudentFlag"
      :data="tempEdit"
      :close="() => (detailStudentFlag = false)"
    />

    <ModalConfirm
      :title="confirmTitle"
      :close="() => (this.confirmModal = false)"
      :loading="confirmLoading"
      :isOpen="confirmModal"
      :save="confirmAction"
    >
      {{ confirmText }}
    </ModalConfirm>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
import { checkPrivilages } from "@/utils/privilages";
moment.locale("id");

import {
  get_detail_elearning,
  list_content,
  edit_content,
  delete_content,
  elearning_content_update_status
} from "@/api/admin/academic/eLearning";

import AddContent from "./components/AddContent";
import DetailContent from "./components/DetailContent";
import CopyContent from "./components/CopyContent";
import ModalConfirm from "@/components/ModalConfirm";
import Pagination from "@/components/Pagination";
import truncate from "truncate-html";

import { getTeacherByLesson } from "@/api/admin/admin";

export default {
  components: {
    AddContent,
    CopyContent,
    ModalConfirm,
    Pagination,
    DetailContent
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY");
    }
  },
  data() {
    return {
      param: this.$route.params,
      modalAddStatus: false,
      modalCopyStatus: false,
      detailStudentFlag: false,
      lessonId: this.$route.params.id,
      selectedElearning: [],
      elearning: {
        name: "",
        sub: "",
        title: "",
        kd: "",
        date: ""
      },
      queryData: {
        elearning: this.$route.params.elearning,
        page: 1,
        limit: 10,
        filter: {
          status: [],
          type: []
        },
        order: {}
      },
      tableHeadersStudent: [
        {
          text: i18n.t("elearning.title"),
          align: "left",
          value: "title"
        },
        {
          text: i18n.t("elearning.table.description"),
          align: "left",
          value: "description"
        },
        {
          text: i18n.t("elearning.table.file_type"),
          sortable: false,
          value: "type"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 75
        }
      ],
      tableHeaders: [
        {
          text: i18n.t("elearning.title"),
          align: "left",
          value: "title"
        },
        {
          text: i18n.t("elearning.table.description"),
          align: "left",
          value: "description"
        },
        {
          text: i18n.t("elearning.table.file_type"),
          sortable: false,
          value: "type"
        },
        {
          text: i18n.t("elearning.table.status"),
          sortable: false,
          value: "status"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 75
        }
      ],
      loading: false,
      total: 0,
      length: 0,
      dataTable: [],
      confirmTitle: "",
      confirmModal: false,
      confirmLoading: false,
      confirmType: "",
      confirmId: null,
      confirmText: "",
      tempEdit: {},
      content: "",
      teacherCreator: null
    };
  },
  async created() {
    await this.getTeacherByLessonHandler();
    await this.getDetailElearning();
    this.getData();
    this.detailStudentFlag = false;
    this.confirmModal = false;
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    darkmode() {
      return this.$store.getters.g_darkmode;
    },
    g_user() {
      return this.$store.getters.g_user;
    }
  },
  methods: {
    getTeacherByLessonHandler() {
      const p = this.$route.params;
      const query = {
        lesson: p.id,
        classroom: p.class
      };
      getTeacherByLesson(query).then(res => {
        if (res.data.code) {
          this.teacherCreator = res.data.data;
        }
      });
    },
    isTeacherCreator() {
      if (this.teacherCreator && this.teacherCreator.length > 0) {
        const teacherId = this.teacherCreator.map(r => r.id_person);
        if (this.g_user.role_type == "ADMIN") {
          return true;
        } else {
          return teacherId.indexOf(this.g_user.person) >= 0;
        }
      } else {
        return false;
      }
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    getUrl(item) {
      if (item.type == "YOUTUBE") {
        const url = item.file;
        if (url) {
          let video_id = url.split("v=")[1];
          if (video_id) {
            const ampersandPosition = video_id.indexOf("&");
            if (ampersandPosition != -1) {
              video_id = video_id.substring(0, ampersandPosition);
            }
            const splitUrl = url.split("watch?v=");
            const newUrl = splitUrl[0] + "embed/" + video_id;
            if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.invalid_youtube_url"),
                color: "error"
              });
              return null;
            } else {
              return newUrl;
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.invalid_youtube_url"),
              color: "error"
            });
          }
        }
      } else {
        return item.file;
      }
    },
    checkPrivilages: name => checkPrivilages(name),
    getDetailElearning() {
      get_detail_elearning({ elearning: this.$route.params.elearning }).then(
        res => {
          if (res.data.code) {
            const d = res.data.data;

            this.elearning = {
              name: d.lesson_name,
              sub: d.sub,
              title: d.title,
              kd: d.basic_competencies,
              date: d.created_at
            };
          }
        }
      );
    },
    confirmAction() {
      this.confirmLoading = true;
      let status = "";
      switch (this.confirmType) {
        case "publish":
          status = "PUBLISHED";
          break;
        case "unpublish":
          status = "UNPUBLISHED";
          break;
        case "delete":
          status = "DELETE";
          break;
        default:
          break;
      }

      if (this.confirmType == "delete") {
        delete_content({
          elearning_contents: this.confirmId
        })
          .then(res => {
            if (res.data.code) {
              this.getData();
              this.confirmModal = false;
              this.selectedElearning = [];
            }

            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: res.data.code ? "success" : "error"
            });
            this.confirmLoading = false;
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      } else {
        if (this.selectedElearning.length > 0) {
          elearning_content_update_status({
            elearning_contents: this.confirmId,
            status: status
          })
            .then(res => {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: res.data.code ? "success" : "error"
              });

              if (res.data.code) {
                this.getData();
                this.confirmModal = false;
                this.selectedElearning = [];
              }
              this.confirmLoading = false;
            })
            .catch(() => {
              this.confirmLoading = false;
            });
        } else {
          edit_content({
            elearning_content: this.confirmId,
            status: status
          })
            .then(res => {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: res.data.code ? "success" : "error"
              });

              if (res.data.code) {
                this.getData();
                this.confirmModal = false;
              }
              this.confirmLoading = false;
            })
            .catch(() => {
              this.confirmLoading = false;
            });
        }
      }
    },
    setAction(data, type) {
      this.confirmType = type;

      if (data) this.confirmId = [data.id];
      else this.confirmId = this.selectedElearning.map(r => r.id);
      switch (type) {
        case "publish":
          this.confirmTitle = i18n.t("elearning.confirm.publish");
          this.confirmText = i18n.t("elearning.confirm.publish_text");
          break;
        case "unpublish":
          this.confirmTitle = i18n.t("elearning.confirm.unpublish");
          this.confirmText = i18n.t("elearning.confirm.unpublish_text");
          break;
        case "delete":
          this.confirmTitle = i18n.t("elearning.confirm.delete");
          this.confirmText = i18n.t("elearning.confirm.delete_text");
          break;
        case "viewstudent":
          this.$router.push(
            `/academic/e-learning/${this.$route.params.elearning}/content/${data.id}`
          );
          break;
        default:
          this.tempEdit = data;
          this.modalAddStatus = true;
          break;
      }
      if (type !== "edit" && type !== "viewstudent") this.confirmModal = true;
    },
    getData() {
      this.loading = true;
      list_content(this.queryData)
        .then(res => {
          if (res.data.code) {
            const data = res.data.data.data;
            const tempData = [];
            data.map(r => {
              let d = r;
              d.type = d.type == "PDF" ? "DOCUMENT" : d.type;
              tempData.push(d);
            });
            this.dataTable = tempData;
            this.total = res.data.data.total;
            this.length = res.data.data.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

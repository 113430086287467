<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="800"
    persistent
  >
    <v-card>
      <v-card-title>
        {{
          tempEdit.id
            ? $t("elearning.edit_elearning_content")
            : $t("elearning.add_elearning_content")
        }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModalHandler(null)">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid">
          <v-row no-gutters class="pt-2">
            <v-text-field
              v-model="form.title"
              :rules="[v => !!v || $t('app.required')]"
              :label="$t('elearning.title')"
              outlined
              dense
            ></v-text-field>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="12" sm="2">
              {{ $t("elearning.choose_file_type") }}
            </v-col>
            <v-col cols="12" sm="12">
              <v-radio-group v-model="form.type" row hide-details class="mt-0">
                <v-radio label="Youtube" value="youtube" @change="changeType" />
                <v-radio
                  :label="$t('app.image')"
                  value="image"
                  @change="changeType"
                />
                <v-radio
                  :label="$t('elearning.document')"
                  value="pdf"
                  @change="changeType"
                />
                <v-radio label="Video" value="video" @change="changeType" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-row v-if="form.type" no-gutters>
            <v-col cols="12" sm="2" class="mb-2">
              {{ labelFile() }}
            </v-col>
            <v-col cols="12" sm="10">
              <v-text-field
                v-if="form.type == 'youtube'"
                v-model="form.url"
                :rules="[v => !!v || $t('app.required')]"
                label="Link Youtube"
                prepend-inner-icon="mdi-link-variant"
                outlined
                clearable
                dense
              ></v-text-field>
              <v-file-input
                v-if="!form.url && form.type == 'image'"
                v-model="file"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('app.upload') + ' ' + $t('app.image')"
                :loading="loadingUpload"
                prepend-inner-icon="mdi-image"
                prepend-icon=""
                outlined
                dense
                accept="image/*"
                @change="uploadFile('image')"
                @click:clear="changeType"
              ></v-file-input>
              <v-file-input
                v-if="!form.url && form.type == 'pdf'"
                v-model="file"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('app.upload') + ' ' + $t('elearning.document')"
                :loading="loadingUpload"
                prepend-inner-icon="mdi-file-document"
                prepend-icon=""
                outlined
                dense
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf"
                @change="uploadFile('pdf')"
                @click:clear="changeType"
              ></v-file-input>
              <v-file-input
                v-if="!form.url && form.type == 'video'"
                v-model="file"
                :rules="[v => !!v || $t('app.required')]"
                :label="$t('app.upload') + ' Video'"
                :loading="loadingUpload"
                prepend-inner-icon="mdi-video"
                prepend-icon=""
                outlined
                dense
                accept="video/mp4, video/ogg, video/webm"
                @change="uploadFile('video')"
                @click:clear="changeType"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-row
                v-if="form.url && form.type != 'youtube'"
                class="mb-2"
                no-gutters
              >
                <v-spacer></v-spacer>
                <v-btn color="error" @click="setDeleteFile()"
                  ><v-icon class="mr-2">mdi-delete-forever</v-icon>
                  {{ $t("app.delete") }}
                </v-btn>
              </v-row>

              <v-img
                v-if="form.url && form.type == 'image'"
                :src="getUrl()"
                class="rounded-lg"
                height="auto"
                width="100%"
              >
              </v-img>

              <iframe
                v-if="form.url && form.type == 'youtube'"
                :src="getUrl()"
                frameborder="0"
                height="350px"
                width="100%"
                allowfullscreen
              ></iframe>

              <iframe
                v-if="
                  form.url && form.type == 'pdf' && isPDF(form.url) != 'pdf'
                "
                :src="
                  `https://view.officeapps.live.com/op/view.aspx?src=${form.url}`
                "
                height="400px"
                width="100%"
              />

              <iframe
                v-if="
                  form.url && form.type == 'pdf' && isPDF(form.url) == 'pdf'
                "
                :src="
                  `https://docs.google.com/viewer?url=${form.url}&embedded=true`
                "
                height="400px"
                width="100%"
              />

              <!-- :src="
                  `https://docs.google.com/viewer?url=${form.url}&embedded=true`
                " -->

              <video
                v-if="form.url && form.type == 'video'"
                height="auto"
                width="100%"
                controls
              >
                <source :src="getUrl()" />
              </video>
            </v-col>
          </v-row>
          <v-divider v-if="form.type" class="my-6"></v-divider>
          <v-row no-gutters>
            <v-col cols="12" sm="2" class="mb-2">
              {{ $t("app.description") }}
            </v-col>
            <v-col cols="12" sm="10">
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters class="mt-3">
          <v-spacer />
          <v-btn
            :disabled="!valid || loadingUpload"
            :loading="loadingSubmit"
            class="mr-2"
            color="primary"
            outlined
            depressed
            @click="submit('UNPUBLISHED')"
          >
            {{ $t("elearning.save_unpublised") }}
          </v-btn>
          <v-btn
            :disabled="!valid || loadingUpload"
            :loading="loadingSubmit"
            color="primary"
            depressed
            @click="submit('PUBLISHED')"
          >
            {{ $t("elearning.save_published") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { uploadFile, deleteFile } from "@/api/admin/admin";
import { create_content, edit_content } from "@/api/admin/academic/eLearning";
import { sliceAwsPath } from "@/utils/uploadS3";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import katex from "katex";
import "katex/dist/katex.min.css";

export default {
  props: {
    dialog: Boolean,
    lessonId: [String, Number],
    closeModal: Function,
    tempEdit: Object
  },
  components: {
    quillEditor
  },
  data() {
    return {
      // allowedExt: ".pdf, .ppt, .pptx, .xls, .xlsx, .doc, .docx",
      valid: false,
      basicCompetenciesList: [],
      loadingCompetencies: false,
      file: null,
      loadingUpload: false,
      loadingSubmit: false,
      moduleFileName: "ELEARNING",
      form: {
        id: "",
        title: "",
        type: "youtube",
        url: "",
        description: ""
      },
      tempDeleteFile: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["formula", "link"]
          ]
        }
      }
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        if (this.tempEdit.id) {
          const d = this.tempEdit;
          this.form.title = d.title;
          this.form.id = d.id;
          this.form.type = d.type == "DOCUMENT" ? "pdf" : d.type.toLowerCase();
          this.form.url = d.file;
          this.form.description = d.description;
        } else {
          this.form.title = "";
          this.form.id = "";
          this.form.type = "";
          this.form.url = "";
          this.form.description = "";
        }
      }
    }
  },
  created() {
    window.katex = katex;
  },
  methods: {
    isPDF(path) {
      if (this.form.type != "youtube" && this.form.type != "video") {
        var extension = path.substr(path.lastIndexOf(".") + 1);
        return extension;
      }
    },
    setDeleteFile() {
      this.form.type = "";
      this.tempDeleteFile = this.form.url;
      this.$nextTick(() => {
        this.form.url = "";
      });
    },
    async closeModalHandler(param) {
      await this.$refs.form.reset();
      if (param == "save") {
        this.closeModal("save");
      } else {
        this.closeModal();
        if (this.form.url && !this.tempEdit.id) {
          const path = await sliceAwsPath(this.form.url);
          await deleteFile(path, this.moduleFileName);
        }
      }
    },
    async changeType() {
      if (this.form.type != "youtube" && this.form.url) {
        const path = await sliceAwsPath(this.form.url);
        if (!this.tempEdit.id) await deleteFile(path, this.moduleFileName);
      }
      this.form.url = "";
      this.file = null;
    },
    getUrl() {
      if (this.form.type == "youtube") {
        const url = this.form.url;
        if (url) {
          let video_id = url.split("v=")[1];
          if (video_id) {
            this.valid = true;
            const ampersandPosition = video_id.indexOf("&");
            if (ampersandPosition != -1) {
              video_id = video_id.substring(0, ampersandPosition);
            }
            const splitUrl = url.split("watch?v=");
            const newUrl = splitUrl[0] + "embed/" + video_id;
            if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.invalid_youtube_url"),
                color: "error"
              });
              this.valid = false;
              return null;
            } else {
              return newUrl;
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.invalid_youtube_url"),
              color: "error"
            });
            this.valid = false;
          }
        }
      } else {
        return this.form.url;
      }
    },
    uploadFile(param) {
      this.form.url = "";
      if (this.file) {
        this.loadingUpload = true;
        let bodyImage = new FormData();
        bodyImage.set("path", `elearning/${param}`);
        bodyImage.set("module", this.moduleFileName);
        bodyImage.append("file", this.file);
        uploadFile(bodyImage)
          .then(res => {
            if (res.data.code) {
              this.form.url = res.data.data.path;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingUpload = false;
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
    labelFile() {
      switch (this.form.type) {
        case "youtube":
          return "Link Youtube";
        default:
          return this.$i18n.t("elearning.choose_file");
      }
    },
    async submit(type) {
      if (this.$refs.form.validate()) {
        this.loadingSubmit = true;
        const d = this.form;

        if (this.tempDeleteFile) {
          const path = await sliceAwsPath(this.tempDeleteFile);
          await deleteFile(path, this.moduleFileName);
        }

        if (this.tempEdit.id) {
          edit_content({
            title: d.title,
            elearning_content: d.id,
            description: d.description,
            type: d.type,
            file: d.url
          })
            .then(async res => {
              const { code, data, message } = res.data;
              if (code) {
                this.$socket.emit("serverNotif", {
                  recipient: data.recipient,
                  data: data
                });
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_edit"),
                  color: "success"
                });
                await this.$refs.form.reset();
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        } else {
          create_content({
            title: d.title,
            elearning: this.$route.params.elearning,
            description: d.description,
            type: d.type,
            file: d.url,
            status: type
          })
            .then(res => {
              const { code, data, message } = res.data;
              if (code) {
                this.$socket.emit("serverNotif", {
                  recipient: data.recipient,
                  data: data
                });
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_save"),
                  color: "success"
                });
                this.$refs.form.reset();
                this.closeModalHandler("save");
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => {
              this.loadingSubmit = false;
            });
        }
      } else {
        this.$nextTick(() => {
          const element = document.querySelector(".v-input.error--text");
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }
        });
      }
    }
  }
};
</script>

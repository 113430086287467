<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-text class="pt-6 pb-2">
        <h2 v-html="data.title"></h2>
        <v-divider class="my-4"></v-divider>
        <v-sheet class="mb-4">
          <v-img
            v-if="data.type == 'IMAGE'"
            :src="getUrl()"
            class="rounded-lg"
            height="auto"
            width="100%"
          ></v-img>

          <iframe
            v-if="data.type == 'YOUTUBE'"
            :src="getUrl()"
            frameborder="0"
            height="350px"
            width="100%"
            allowfullscreen
          ></iframe>

          <iframe
            v-if="data.type == 'PDF'"
            :src="
              `https://docs.google.com/viewer?url=${getUrl()}&embedded=true`
            "
            height="400px"
            width="100%"
          ></iframe>

          <video
            v-if="data.type == 'VIDEO'"
            height="auto"
            width="100%"
            controls
          >
            <source :src="getUrl()" />
          </video>
        </v-sheet>
        <v-card flat>
          <h4 class="mb-2">{{ $t("app.description") }}</h4>
          <v-divider class="pb-2"></v-divider>
          <span v-html="data.description"></span>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mx-4 mb-2"
          depressed
          small
          @click="close"
          >{{ $t("app.cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    data: Object,
    close: Function
  },
  methods: {
    getUrl() {
      if (this.data.type == "youtube") {
        const url = this.data.url;
        if (url) {
          let video_id = url.split("v=")[1];
          if (video_id) {
            const ampersandPosition = video_id.indexOf("&");
            if (ampersandPosition != -1) {
              video_id = video_id.substring(0, ampersandPosition);
            }
            const splitUrl = url.split("watch?v=");
            const newUrl = splitUrl[0] + "embed/" + video_id;
            if (splitUrl[0] != "https://www.youtube.com/" || !splitUrl[1]) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("app.invalid_youtube_url"),
                color: "error"
              });
              return null;
            } else {
              return newUrl;
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("app.invalid_youtube_url"),
              color: "error"
            });
          }
        }
      } else {
        return this.data.url;
      }
    }
  }
};
</script>

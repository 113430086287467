import request from "@/utils/request";

export function get_list_elearning(data) {
  return request({
    method: "POST",
    url: "elearning/list",
    data: data
  });
}

export function get_detail_lesson(data) {
  return request({
    method: "POST",
    url: "lesson/detail",
    data: data
  });
}

export function create_elearning(data) {
  return request({
    method: "POST",
    url: "elearning/create",
    data: data
  });
}

export function elearning_edit(data) {
  return request({
    method: "POST",
    url: "elearning/edit",
    data: data
  });
}

export function elearning_update_status(data) {
  return request({
    method: "POST",
    url: "elearning/update_status",
    data: data
  });
}

export function elearning_delete(data) {
  return request({
    method: "POST",
    url: "elearning/delete",
    data: data
  });
}

export function list_elearning_by_class(data) {
  return request({
    method: "POST",
    url: "elearning/list_by_class",
    data: data
  });
}

export function get_detail_elearning(data) {
  return request({
    method: "POST",
    url: "elearning/detail",
    data: data
  });
}

export function list_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/list",
    data: data
  });
}

export function create_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/create",
    data: data
  });
}

export function edit_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/edit",
    data: data
  });
}

export function elearning_content_update_status(data) {
  return request({
    method: "POST",
    url: "elearning_content/update_status",
    data: data
  });
}

export function delete_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/delete",
    data: data
  });
}

export function copy_to_mapel(data) {
  return request({
    method: "POST",
    url: "elearning/copy",
    data: data
  });
}

export function copy_to_lesson(data) {
  return request({
    method: "POST",
    url: "elearning/copy_to_lesson",
    data: data
  });
}

export function copy_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/copy",
    data: data
  });
}

export function detail_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/detail",
    data: data
    // { elearning_content: id_content }
  });
}

export function get_view_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/get_view",
    data: data
  });
}

export function get_like_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/get_like",
    data: data
  });
}

export function set_like_content(data) {
  return request({
    method: "POST",
    url: "elearning_content/like_dis",
    data: data
  });
}

export function get_comment_content(data) {
  return request({
    method: "POST",
    url: "elearning_comment/get",
    data: data
  });
}

export function add_comment_content(data) {
  return request({
    method: "POST",
    url: "elearning_comment/add",
    data: data
  });
}

export function delete_comment_content(data) {
  return request({
    method: "POST",
    url: "elearning_comment/remove",
    data: data
  });
}

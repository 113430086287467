<template>
  <v-dialog v-model="dialog" max-width="590" persistent>
    <v-card>
      <v-card-title>
        <div>{{ $t("elearning.copy_content_from") }}</div>
        <v-spacer></v-spacer>
        <v-icon @click="closeModalHandler">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-6">
        <v-form ref="form" v-model="valid">
          <FilterSchoolYear
            :triggerChange="dialog"
            :itemDisable="itemDisable"
            :dataFilter="dataFilter"
            isVertical
            widthHorizontal
            notMultiple
            @handler="getFilter"
          />
          <v-row no-gutters justify="space-around">
            <v-autocomplete
              v-model="filter.elearning"
              :label="$t('school_year.header.select_elearning')"
              :items="filterList.elearning"
              item-value="id"
              item-text="title"
              :loading="loadingFilter.elearning"
              :disabled="filterList.elearning.length == 0"
              class="select-250"
              outlined
              dense
            >
            </v-autocomplete>
            <v-select
              v-model="filter.type"
              :label="$t('school_year.header.select_type')"
              :items="filterList.type"
              item-value="value"
              item-text="label"
              :loading="loadingFilter.type"
              :disabled="!filter.elearning"
              class="select-250"
              outlined
              multiple
              dense
            >
            </v-select>
          </v-row>

          <v-btn
            :disabled="!filter.elearning"
            :loading="searchContentLoading"
            color="primary"
            class="mt-2"
            depressed
            block
            @click="searchContent()"
            >{{ $t("app.search") }}</v-btn
          >
        </v-form>

        <v-alert
          v-if="searchContentNotFound"
          color="warning"
          class="white--text mt-3"
          dense
        >
          {{ $t("elearning.content_not_found") }}
        </v-alert>

        <v-divider class="my-4"></v-divider>

        <v-sheet v-if="contentList.length > 0" class="mt-4">
          <v-card>
            <v-data-table
              v-model="selectedContent"
              :headers="headersContent"
              :items="contentList"
              item-key="id"
              show-select
              single-expand
              show-expand
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-3">
                  <div v-if="item.description">
                    <strong>{{ $t("app.description") }}: </strong><br />
                    <span v-html="item.description"></span>
                  </div>
                  <v-divider></v-divider>
                  <v-row no-gutters class="mt-2">
                    <v-row
                      v-if="item.file && item.type == 'IMAGE'"
                      class="overflow-auto"
                      style="height: 400px; width: auto"
                      justify="center"
                      align="center"
                    >
                      <v-img :src="getUrl(item)" class="rounded-lg"></v-img>
                    </v-row>

                    <iframe
                      v-if="getUrl(item) && item.type == 'YOUTUBE'"
                      :src="getUrl(item)"
                      frameborder="0"
                      height="350px"
                      width="100%"
                      allowfullscreen
                    ></iframe>

                    <iframe
                      v-if="
                        item.file &&
                          item.type == 'pdf' &&
                          isPDF(form.url) != 'pdf'
                      "
                      :src="
                        `https://view.officeapps.live.com/op/view.aspx?src=${getUrl(
                          item
                        )}`
                      "
                      height="400px"
                      width="100%"
                    />

                    <iframe
                      v-if="
                        item.file &&
                          item.type == 'pdf' &&
                          isPDF(form.url) == 'pdf'
                      "
                      :src="
                        `https://docs.google.com/viewer?url=${form.url}&embedded=true`
                      "
                      height="400px"
                      width="100%"
                    />

                    <video
                      v-if="item.file && item.type == 'VIDEO'"
                      height="auto"
                      width="100%"
                      controls
                    >
                      <source :src="getUrl(item)" />
                    </video>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-row no-gutters class="mt-2">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid || selectedContent.length == 0"
              :loading="loadingSubmit"
              color="primary"
              depressed
              @click="submit"
              class="mt-3"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-row>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  list_elearning_by_class,
  list_content,
  copy_content
} from "@/api/admin/academic/eLearning";
import FilterSchoolYear from "@/views/admin/components/filterSchooYear";

export default {
  components: { FilterSchoolYear },
  props: {
    dialog: Boolean,
    lessonId: [String, Number],
    lesson: String,
    closeModal: Function,
    getData: Function,
    getUrl: Function
  },
  data() {
    return {
      valid: false,
      contentList: [],
      selectedContent: [],
      classList: [],
      searchContentLoading: false,
      loadingClass: false,
      searchContentNotFound: false,
      loadingSubmit: false,
      headersContent: [
        {
          text: this.$i18n.t("elearning.title"),
          sortable: false,
          value: "title"
        },
        {
          text: this.$i18n.t("elearning.table.file_type"),
          sortable: false,
          value: "type"
        }
      ],
      filter: {
        school_year: "",
        grade: "",
        class: "",
        elearning: "",
        type: ""
      },
      filterList: {
        schoolYear: [],
        grade: [],
        class: [],
        elearning: [],
        type: [
          { value: "YOUTUBE", label: "Youtube" },
          { value: "IMAGE", label: this.$i18n.t("app.image") },
          { value: "PDF", label: "PDF" },
          { value: "VIDEO", label: "Video" }
        ]
      },
      loadingFilter: {
        elearning: false,
        type: false
      },
      dataFilter: {},
      itemDisable: {}
    };
  },
  watch: {
    dialog() {
      this.$nextTick(() => {
        const p = this.$route.params;
        const data = {
          year: [Number(p.year)],
          grade: Number(p.grade),
          class: Number(p.class)
        };
        this.itemDisable = data;
        this.dataFilter.year = data.year;
        this.dataFilter.grade = data.grade;
      });
    }
  },
  methods: {
    isPDF(path) {
      var extension = path.substr(path.lastIndexOf(".") + 1);
      return extension;
    },
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.filter.school_year = res.data.id;
          break;
        case "select_grade":
          this.filter.grade = res.data;
          break;
        default:
          this.filter.class = res.data;
          break;
      }
      if (res.type == "select_class") this.searchElearning();
    },
    closeModalHandler() {
      this.$refs.form.reset();
      this.contentList = [];
      this.closeModal();
    },
    searchElearning() {
      this.loadingFilter.elearning = true;
      list_elearning_by_class({
        school_year: this.filter.school_year,
        classroom: this.filter.class
      })
        .then(res => {
          if (res.data.code) {
            const data = res.data.data;
            this.filterList.elearning = data;
            if (data.length == 0) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: this.$i18n.t("elearning.elearning_not_found"),
                color: "warning"
              });
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingFilter.elearning = false;
        })
        .catch(() => {
          this.loadingFilter.elearning = false;
        });
    },
    searchContent() {
      this.searchContentLoading = true;
      this.searchContentNotFound = false;
      list_content({
        elearning: this.filter.elearning,
        is_paginate: "FALSE",
        filter: {
          status: [],
          type: this.filter.type
        },
        order: {
          type: "DESC",
          status: "DESC"
        }
      })
        .then(res => {
          if (res.data.code) {
            this.contentList = res.data.data;
            if (this.contentList.length == 0) this.searchContentNotFound = true;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.searchContentLoading = false;
        })
        .catch(() => (this.searchContentLoading = false));
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (this.selectedContent.length > 0) {
          this.loadingSubmit = true;
          const contentId = this.selectedContent.map(r => r.id);
          const data = {
            elearning_content: contentId,
            to_class: this.$route.params.class,
            to_lesson: this.lessonId,
            to_elearning: Number(this.$route.params.elearning),
            from_elearning: Number(this.filter.elearning),
            from_class: Number(this.filter.class)
          };

          copy_content(data)
            .then(async res => {
              console.log(data);
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: this.$i18n.t("app.success_copy"),
                  color: "success"
                });
                await res.data.data.map(d => {
                  if (d.code) {
                    this.$socket.emit("serverNotif", {
                      recipient: d.recipient,
                      data: d
                    });
                  }
                });
                await this.getData();
                this.closeModalHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSubmit = false;
            })
            .catch(() => (this.loadingSubmit = false));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

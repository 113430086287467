<template>
  <v-dialog v-model="isOpen" max-width="400" persistent>
    <v-card>
      <v-card-title v-if="title">
        {{ title }}
      </v-card-title>
      <v-divider v-if="title"></v-divider>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="1">
            <v-icon color="warning" class="mr-2">mdi-alert-circle</v-icon>
          </v-col>
          <v-col cols="11">
            <slot v-if="content != null"> <span v-html="content"></span></slot>
            <slot v-else>{{ $t("app.confirm_delete") }}</slot>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!onlyCancel">
        <v-spacer />
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="close(false)"
        >
          {{ textNo ? textNo : $t("app.no") }}
        </v-btn>
        <v-btn
          :loading="loading"
          color="gradient-primary"
          dark
          depressed
          small
          @click="save"
        >
          {{ textYes ? textYes : $t("app.yes") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn text depressed outlined color="primary" @click="close(false)">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    isOpen: Boolean,
    save: Function,
    close: Function,
    loading: Boolean,
    content: String,
    onlyCancel: Boolean,
    textNo: String,
    textYes: String
  }
};
</script>

import request from "@/utils/request";

export function get_school_year_list(isMaster) {
  let url = "school_year/all_list";
  if (isMaster) url = "school_year/master_list";

  return request({
    method: "POST",
    url: url
  });
}

export function get_grade_list(data) {
  return request({
    method: "POST",
    url: "class/grade_lists",
    data: data
  });
}

export function get_class_list(param, isMaster) {
  let url = "class/list";
  const paramMajor = {
    major_id: param.major_id,
    expertise_id: param.expertise_id,
    expertise_competency_id: param.expertise_competency_id
  };
  let queryData = {
    grade: param.grade,
    year: param.school_year,
    type: param.type
  };

  if (isMaster) {
    url = "class/list_by_year";
    queryData = {
      grade: param.grade,
      year_master: param.school_year,
      type: param.type
    };
  }

  if (param.type) queryData.type = param.type;

  return request({
    method: "POST",
    url: url,
    data: { ...queryData, ...paramMajor }
  });
}

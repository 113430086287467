<template>
  <v-row v-if="length > 0" no-gutters>
    <v-row
      v-if="$vuetify.breakpoint.xsOnly"
      class="mt-3"
      justify="center"
      no-gutters
    >
      <v-select
        :items="items"
        v-model="perPage"
        hide-details
        outlined
      ></v-select>
      <v-pagination
        :value="page"
        :length="length"
        :class="darkmode ? 'custom-sistesi-darkmode' : 'custom-sistesi-light'"
        class="mt-2"
        @input="getValue"
      />
    </v-row>
    <v-row v-else justify="space-between" class="mt-5" no-gutters>
      <div>
        <span class="body-2">
          {{ $t("components.showing") }} {{ showing() }}
          {{ $t("components.of") }} {{ total }} |
        </span>
        <v-select
          :items="items"
          v-model="perPage"
          hide-details
          outlined
          dense
          class="caption mx-2 d-inline-block"
          style="width: 80px;"
        ></v-select>
        <span class="body-2">{{ $t("components.per_page") }}</span>
      </div>

      <v-pagination
        :value="page"
        :length="length"
        :class="darkmode ? 'custom-sistesi-darkmode' : 'custom-sistesi-light'"
        :total-visible="10"
        @input="getValue"
      />
    </v-row>
  </v-row>
</template>

<script>
import store from "@/store";

export default {
  props: {
    currentPage: {
      type: Number,
      default: () => 0
    },
    length: {
      type: Number,
      default: () => 0
    },
    limit: {
      type: Number,
      default: () => 10
    },
    total: {
      type: Number,
      default: () => 0
    },
    handler: Function
  },
  data() {
    return {
      items: [5, 10, 25, 50, 100]
    };
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    },
    page() {
      return this.currentPage;
    },
    perPage: {
      get() {
        return this.limit;
      },
      set(n) {
        if (n) this.handler(1, n);
      }
    }
  },
  methods: {
    getValue(val) {
      this.handler(val, this.perPage);
    },
    showing() {
      const tot = this.perPage * this.page;
      const totLength = this.perPage * this.page;
      const showing = `${(tot && tot + 1) - this.perPage} - ${
        totLength > this.total ? this.total : totLength
      }`;
      return showing;
    }
  }
};
</script>

<style lang="scss">
.custom-sistesi-light {
  .v-pagination {
    width: inherit !important;
    .v-pagination__item {
      height: 25px;
      min-width: 25px;
      margin: 0px 2px !important;
      padding: 0px !important;
      font-size: 0.8rem;
      color: lightgray !important;
      background: white !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
    .v-pagination__navigation {
      width: 20px;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
      .v-icon {
        font-size: 20px;
        color: #27ace2;
      }
      &:focus {
        outline: none;
      }
    }
    .v-pagination__item {
      &:focus {
        outline: none;
      }
    }
    .v-pagination__item--active {
      color: black !important;
    }
  }
}

.custom-sistesi-darkmode {
  .v-pagination {
    .v-pagination__item {
      height: 25px;
      min-width: 25px;
      margin: 0px !important;
      padding: 0px !important;
      font-size: 14px;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
    .v-pagination__navigation {
      width: 20px;
    }
  }
}
</style>
